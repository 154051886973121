<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 75%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .modal-content {
    min-height: 46rem !important;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="xl"
    v-model="showModal"
    :title="'Material Detail'"
  >
    <div class="row" v-if="loadingData">
      <div class="col-12 text-center" style="padding: 10px">
        <loadingSpinner></loadingSpinner>
        <br />
        <h6>Please Wait, we are get the details.</h6>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-md-12">
        <div class="row">
          <div class="col-6">
            <label for="">Name:</label>
            <label for="" class="detail ml-2">{{ materialDetail.name }}</label>
          </div>

          <!-- <li>
                    <label for="">Date of Birth: </label>
                    <label for="" class="detail">{{
                      appointmentData.dob
                    }}</label>
                  </li> -->
        </div>

        <div class="log mt-5" style="min-height: 27rem !important">
          <div class="row">
            <div class="col-md-12" style="background: white">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>SN</th>
                    <th style="width: 10px">Parameter</th>
                    <th scope="col">Specification</th>
                  </tr>
                </thead>
                <tbody v-if="!loadingData && materialDetail.details.length > 0">
                  <tr
                    style="width: 100px !important"
                    v-for="(item, index) in materialDetail.details"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td style="width: 10px">{{ item.parameter }}</td>
                    <td>{{ item.specification }}</td>
                  </tr>
                </tbody>

                <tbody v-if="!loadingData && materialDetail.details.length < 1">
                  <tr>
                    <td colspan="24" class="text-center">No record(s) found</td>
                  </tr>
                </tbody>

                <tbody v-if="loadingData">
                  <tr>
                    <th class="text-center" colspan="24">
                      <loadingSpinner></loadingSpinner>
                    </th>
                  </tr>
                </tbody>
                <!----><!---->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="!hasData && !loadingData">
      <div class="col-md-12 col-12" style="padding: 10px">
        <h5>Sorry, we didn't found details</h5>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: false,
    },
    indexNum: {
      type: Number,
      default: -1,
    },
    materialDetail: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },

  components: {
    loadingSpinner,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    close() {
      this.$emit("closemodal");
    },
  },

  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function (value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      return moment(String(fullDate)).format("h:mm a");
    },
    sDateFormate: function (date) {
      if (date == null) {
        return;
      }
      return moment(String(date)).format("dddd, MMMM DD, YYYY");
    },
  },
};
</script>
